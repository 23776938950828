import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Examination } from '../examination';
import { catchError, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiRequestService, HttpError, MAT_ICON, TOAST_STATE, ToastmessageService } from '@prekog/pw-common';
import { Router } from '@angular/router';
import { GetSpecialityRequest } from '../getspecialityrequest';
import { GetSpecialityResponse } from '../getspecialityresponse';
import { AddExaminationResponse } from './addexaminationresponse';
import { AddExaminationRequest } from './addexaminationrequest';

@Component({
    selector: 'app-addexamination',
    templateUrl: './addexamination.component.html',
    styleUrls: ['./addexamination.component.scss']
})
export class AddexaminationComponent implements OnInit {

    examinationForm?: FormGroup;

    examination: Examination = {};

    specialityId = 0;

    getSpecialityByIdUrl = '/admin/get-speciality-by-id';

    specialityName = '';

    regeneroBaseUrl = '';

    addExaminationUrl = '/admin/add-examination';

    showMinMaxPrice = false;

    @ViewChild('priceInput') priceInput!: ElementRef;
    price: number | null = null;
    @ViewChild('minPriceInput') minPriceInput!: ElementRef;
    minPrice: number | null = null;
    @ViewChild('maxPriceInput') maxPriceInput!: ElementRef;
    maxPrice: number | null = null;

    constructor(public _router: Router,
        private _apiRequestService: ApiRequestService,
        @Inject('REGENERO_BASE_URL') private _regeneroBaseUrl: string,
        private _toastMessageService: ToastmessageService, private _fb: FormBuilder) {
        this.regeneroBaseUrl = _regeneroBaseUrl;
    }

    ngOnInit(): void {
        if (!sessionStorage.getItem('token')) {
            this._router.navigateByUrl('/');
        }
        else {
            const lastSegmentOfUrl = window.location.href.split('/').pop();
            const routerLink = lastSegmentOfUrl as string;
            this.specialityId = +routerLink;
            this.getSpecialityById();
            this.createExaminationForm();
        }
    }

    addExamination() {
        this.collectExaminationData();
        const addExaminationRequest: AddExaminationRequest = {
            examination: this.examination
        }

        this._apiRequestService
            .sendRequest<AddExaminationRequest, AddExaminationResponse>(
                this.regeneroBaseUrl,
                true,
                addExaminationRequest,
                true,
                this.addExaminationUrl
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(TOAST_STATE.error, 'HttpError hiba!', MAT_ICON.error);
                        this.dismissError();

                    });
                })
            )
            .subscribe((response: AddExaminationResponse) => {
                this._toastMessageService.displayToastMessage(TOAST_STATE.success, response.responseMessage!, MAT_ICON.success);
                this.dismissError();
                console.log(this.price)
            });
    }

    createExaminationForm() {
        this.examinationForm = this._fb.group({
            name: ['', [Validators.required]]
        });
    }

    collectExaminationData() {
        this.examination = {
            name: this.examinationForm?.controls['name'].value as string | undefined,
            price: this.price!,
            minPrice: this.minPrice!,
            maxPrice: this.maxPrice!,
            specialityId: this.specialityId
        };

    }

    getSpecialityById() {

        const getspecialitybyidRequest: GetSpecialityRequest = {
            specialityId: this.specialityId
        };
        this._apiRequestService
            .sendRequest<GetSpecialityRequest, GetSpecialityResponse>(
                this.regeneroBaseUrl,
                true,
                getspecialitybyidRequest,
                true,
                this.getSpecialityByIdUrl
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(TOAST_STATE.error, 'HttpError hiba!', MAT_ICON.error);
                        this.dismissError();


                    });
                })
            )
            .subscribe((response: GetSpecialityResponse) => {
                this.specialityName = response.speciality?.name!;
            });
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }

    onChangePrice() {
        this.price = this.priceInput.nativeElement.value;
    }

    onChangeMinPrice() {
        this.minPrice = this.minPriceInput.nativeElement.value;
    }

    onChangeMaxPrice() {
        this.maxPrice = this.maxPriceInput.nativeElement.value;
    }

    showMaxMinPrices() {
        this.showMinMaxPrice = !this.showMinMaxPrice;
        this.price = null;
    }
    showPrice() {
        this.showMinMaxPrice = !this.showMinMaxPrice;
        this.minPrice = null;
        this.maxPrice = null;
    }
}
