import { DeletepricepopupComponent } from './../../pricelist/deletepricepopup/deletepricepopup.component';
import { AfterViewChecked, ChangeDetectorRef, Component, Inject, NgZone, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiRequestService, HttpError, MAT_ICON, TOAST_STATE, ToastmessageService, TranslationService } from '@prekog/pw-common';
import { GetSpecialityRequest } from '../../pricelist/getspecialityrequest';
import { GetSpecialityResponse } from '../..//pricelist/getspecialityresponse';
import { Speciality } from '../../specialitypricelist/speciality';
import { GetDoctorResponse } from '../../doctors/getdoctorresponse';
import { DoctorWithChecked } from '../addspeciality/doctorwithchecked';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import { Doctor } from '../../doctors/doctor';
import { MatDialog } from '@angular/material/dialog';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { AddexaminationpopupComponent } from '../addspeciality/addexaminationpopup/addexaminationpopup.component';
import { Examination } from '../../pricelist/examination';
import { UpdateexaminationpopupComponent } from './updateexaminationpopup/updateexaminationpopup.component';
import { DeletedoctorspecialitypopupComponent } from './deletedoctorspecialitypopup/deletedoctorspecialitypopup.component';
import { DoctorSpeciality } from './doctorspeciality';
import { UpdateSpecialityRequest } from './updatespecialityrequest';
import { UpdateSpecialityResponse } from './updatespecialityresponse';
import { SpecialityType } from '../addspeciality/specialitytype';
import { GetSpecialityTypesResponse } from '../addspeciality/getspecialitytypesresponse';

@Component({
    selector: 'app-updatespeciality',
    templateUrl: './updatespeciality.component.html',
    styleUrls: ['./updatespeciality.component.scss']
})
export class UpdatespecialityComponent implements OnInit, AfterViewChecked {

    specialityId = 0;

    getspecialityUrl = '/regenero/get-speciality-by-id';

    regeneroBaseUrl = '';

    specialityForm?: FormGroup;

    htmlForm?: FormGroup;

    speciality: Speciality = {};

    getDoctorsUrl = '/regenero/get-doctors';

    doctors: DoctorWithChecked[] = [];

    isExtraSmall: Observable<BreakpointState>;

    examinationList?: Examination[];

    updateSpecialityUrl = '/admin/update-speciality';

    specialityTypeName:string = '';

    getSpecialityTypesUrl = '/admin/get-speciality-types';

    specialityTypeList:SpecialityType[] = [];

    constructor(
        public _matDialog: MatDialog,
        public _router: Router,
        private _apiRequestService: ApiRequestService,
        @Inject('REGENERO_BASE_URL') private _regeneroBaseUrl: string,
        private _toastMessageService: ToastmessageService,
        private _fb: FormBuilder,
        private _translateService: TranslationService,
        private readonly breakpointObserver: BreakpointObserver,
        private ref: ChangeDetectorRef,
        private ngZone: NgZone
    ) {
        this.regeneroBaseUrl = _regeneroBaseUrl;

        this.isExtraSmall = this.breakpointObserver.observe(Breakpoints.XSmall);

        this.createSpecialityForm();

        this.createHtmlForm();

        this.findSpeciality();

        this.getDoctors();

    }


    ngAfterViewChecked(): void {
        this.ngZone.runOutsideAngular(() => {
            this.ref.detectChanges();
            setTimeout(() => {
                if (sessionStorage.getItem('updateExaminationList')) {
                    const examinationList = JSON.parse(sessionStorage.getItem('updateExaminationList') as string) as Examination[];
                    if (this.examinationList?.length !== examinationList.length) {
                        this.examinationList = [...examinationList];
                    }
                }

            }, 0);
        });
    }


    ngOnInit(): void {
        if (!sessionStorage.getItem('token')) {
            this._router.navigateByUrl('/');
        }
        else {
            if (sessionStorage.getItem('updateExaminationList')) {
                this.examinationList = JSON.parse(sessionStorage.getItem('updateExaminationList') as string) as Examination[];
            }
        }
    }

    createSpecialityForm() {
        this.specialityForm = this._fb.group({
            name: ['', [Validators.required]],
            jobTitle: ['', [Validators.required]]


        });
    }

    getSpecialityTypes() {
        this._apiRequestService
        .sendRequest<{}, GetSpecialityTypesResponse>(this.regeneroBaseUrl, true, {}, true, this.getSpecialityTypesUrl)
        .pipe(
            catchError((error: HttpErrorResponse) => {
                return throwError(() => {
                    const httpError: HttpError = error.error as HttpError;

                    this._toastMessageService.displayToastMessage(TOAST_STATE.error, 'HttpError hiba!', MAT_ICON.error);
                    this.dismissError();


                });
            })
        )
        .subscribe((response: GetSpecialityTypesResponse) => {
            this.specialityTypeList = response.specialityTypeList as SpecialityType[];
        });
    }

    createHtmlForm() {
        this.htmlForm = this._fb.group({
            html: ['', [Validators.required]],

        });
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }

    collectSpecialityData() {
        const specialityType:SpecialityType = this.specialityTypeList.filter(specialityType => specialityType.name === this.specialityTypeName)[0];
        this.speciality = {
            id: this.speciality.id,
            name: this.specialityForm?.controls['name'].value as string | undefined,
            jobTitle: this.specialityForm?.controls['jobTitle'].value as string | undefined,
            description: this.htmlForm?.controls['html'].value as string | undefined,
            isSpeciality: true,
            specialityType:specialityType

        };


    }
    findSpeciality() {
        const lastSegmentOfUrl = window.location.href.split('/').pop();
        const routerLink = lastSegmentOfUrl as string;
        this.specialityId = +routerLink;
        const getSpecialityRequest: GetSpecialityRequest = {
            specialityId: this.specialityId
        };

        this._apiRequestService
            .sendRequest<GetSpecialityRequest, GetSpecialityResponse>(this.regeneroBaseUrl, true, getSpecialityRequest, true, this.getspecialityUrl)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(TOAST_STATE.error, 'HttpError hiba!', MAT_ICON.error);
                        this.dismissError();


                    });
                })
            )
            .subscribe((response: GetSpecialityResponse) => {
                this.speciality = response.speciality!;
                this.specialityTypeName = this.speciality.specialityType?.name!;
                this.getSpecialityTypes();


            });
    }

    openAddExaminationPopup() {
        const popupDialog = this._matDialog.open(UpdateexaminationpopupComponent, {
            data: this.speciality.id,
            width: 'calc(100% - 50px)',
            maxWidth: '100vw',
            height: '80%'
        });
        const xsmallDialogSubscription = this.isExtraSmall.subscribe((size: any) => {
            if (size.matches) {
                popupDialog.updateSize('100vw', '100%');
            } else {
                popupDialog.updateSize('calc(60% - 50px)', '');
            }
        });

        popupDialog.afterClosed().subscribe(() => {
            xsmallDialogSubscription.unsubscribe();
        });
    }

    removeExamination(index: number) {
        if (sessionStorage.getItem('updateExaminationList')) {
            sessionStorage.removeItem('updateExaminationList');

        }
        this.examinationList?.splice(index, 1);
        sessionStorage.setItem('updateExaminationList', JSON.stringify(this.examinationList));
        window.location.reload();

    }

    getDoctors() {
        this._apiRequestService
            .sendRequest<{}, GetDoctorResponse>(this.regeneroBaseUrl, true, {}, true, this.getDoctorsUrl)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(TOAST_STATE.error, 'HttpError hiba!', MAT_ICON.error);
                        this.dismissError();


                    });
                })
            )
            .subscribe((response: GetDoctorResponse) => {
                const doctorList = response.doctors as Doctor[];
                const doctors: Doctor[] = doctorList.filter(d => !this.speciality.doctorSpecialityList?.some(d2 => d2.doctor?.routerLink === d.routerLink)) as Doctor[];
                console.log(doctors)
                const doctorWithCheckedList: DoctorWithChecked[] = [];
                doctors.forEach((doctor) => {
                    const doctorWithChecked: DoctorWithChecked = {
                        doctor: doctor,
                        checked: false
                    }
                    doctorWithCheckedList.push(doctorWithChecked)
                })
                this.doctors = [...doctorWithCheckedList];

            });
    }

    updateSpeciality() {
        this.collectSpecialityData();

        const updateSpecialityRequest: UpdateSpecialityRequest = {
            speciality: this.speciality,
            doctorNameList: this.createDoctorNameList(),
            examinationList: this.examinationList
        }

        this._apiRequestService
            .sendRequest<UpdateSpecialityRequest, UpdateSpecialityResponse>(this.regeneroBaseUrl, true, updateSpecialityRequest, true, this.updateSpecialityUrl)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;
                        if (error.status === 422) {
                            alert(this._translateService.translate(httpError.errorMessage!));
                        } else {
                            alert(this._translateService.translate('error.http'));
                        }
                    });
                })
            )
            .subscribe((response: UpdateSpecialityResponse) => {
                this._toastMessageService.displayToastMessage(TOAST_STATE.success, response.responseMessage!, MAT_ICON.success);
                sessionStorage.removeItem('updateExaminationList');
                this.dismissError();
                window.location.reload();




            });

    }

    createDoctorNameList(): Array<string> {
        let doctorNameList: Array<string> = [];
        this.doctors.forEach((doctor) => {
            if (doctor.checked) {
                doctorNameList.push(doctor.doctor?.name!);
            }
        })

        return doctorNameList;
    }

    changeDoctor(doctorWithChecked: DoctorWithChecked) {
        this.doctors?.forEach((doctor => {
            if (doctorWithChecked.doctor?.name === doctor.doctor?.name) {
                doctor.checked = !doctor.checked
            }
        }))
    }

    openDoctorSpecialityPopup(doctorSpeciality: DoctorSpeciality) {

        const popupDialog = this._matDialog.open(DeletedoctorspecialitypopupComponent, {
            data: doctorSpeciality,
            width: 'calc(100% - 50px)',
            maxWidth: '100vw',
            height: '80%'
        });
        const xsmallDialogSubscription = this.isExtraSmall.subscribe((size: any) => {
            if (size.matches) {
                popupDialog.updateSize('100vw', '100%');
            } else {
                popupDialog.updateSize('calc(60% - 50px)', '');
            }
        });

        popupDialog.afterClosed().subscribe(() => {
            xsmallDialogSubscription.unsubscribe();
        });
    }

    removeExaminationFromDb(examinationId: number) {
        const popupDialog = this._matDialog.open(DeletepricepopupComponent, {
            data: examinationId,
            width: 'calc(100% - 50px)',
            maxWidth: '100vw',
            height: '80%'
        });
        const xsmallDialogSubscription = this.isExtraSmall.subscribe((size: any) => {
            if (size.matches) {
                popupDialog.updateSize('100vw', '100%');
            } else {
                popupDialog.updateSize('calc(60% - 50px)', '');
            }
        });

        popupDialog.afterClosed().subscribe(() => {
            xsmallDialogSubscription.unsubscribe();
        });
    }

}
