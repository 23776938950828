import { Component } from '@angular/core';

@Component({
  selector: 'app-specialitytype',
  templateUrl: './specialitytype.component.html',
  styleUrls: ['./specialitytype.component.scss']
})
export class SpecialitytypeComponent {

}
