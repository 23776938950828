export const environment = {
    production: true,
    backendConfiguratorUrl : 'https://configurator.regenero.hu',
    apiUrl: 'https://admin.regenero.hu/',
    downloadUrl:'https://file.regenero.hu/file/downloadFile',
    regeneroBaseUrl:'https://backend.regenero.hu',
    backendAuthUrl: 'https://auth.regenero.hu',
    backendFileManagerUrl: 'https://file.regenero.hu',
    apiUrlRegenero: 'https://regenero.hu'

};
