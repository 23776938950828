import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ApiRequestService, HttpError, MAT_ICON, TOAST_STATE, ToastmessageService } from '@prekog/pw-common';
import { Doctor } from '../doctor';
import { catchError, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { DeleteJobtitleRequest } from './deletejobtitlerequest';
import { DeleteJobtitleResponse } from './deletejobtitleresponse';
import { DoctorJobTitle } from './doctorjobtitle';

@Component({
  selector: 'app-deletejobtitlepopup',
  templateUrl: './deletejobtitlepopup.component.html',
  styleUrls: ['./deletejobtitlepopup.component.scss']
})
export class DeletejobtitlepopupComponent {
    deletJobTitleUrl = '/admin/delete-jobTitle';

    regeneroBaseUrl = '';

    selectedAlign: 'start' | 'center' | 'end';

    constructor(
        private _toastMessageService: ToastmessageService,
        private _apiRequestService: ApiRequestService,
        @Inject('REGENERO_BASE_URL') private _regeneroBaseUrl: string,
        private _matdialog:MatDialog,
        @Inject(MAT_DIALOG_DATA) public doctorJobtitle: DoctorJobTitle
    ) {
        this.regeneroBaseUrl = _regeneroBaseUrl;

        this.selectedAlign = 'end';
    }

      //TODO confirm popup!!!
      deleteBanner() {
        const deleteJobTitleRequest: DeleteJobtitleRequest = {
            doctorSpecialityId: this.doctorJobtitle.doctorSpecialityId
        };

        this._apiRequestService
            .sendRequest<DeleteJobtitleRequest, DeleteJobtitleResponse>(
                this.regeneroBaseUrl,
                true,
                deleteJobTitleRequest,
                true,
                this.deletJobTitleUrl
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(
                            TOAST_STATE.error,
                            'Sikertelen törlés' + httpError.errorMessage,
                            MAT_ICON.error
                        );
                        this.dismissError();
                    });
                })
            )
            .subscribe((response) => {
                this._toastMessageService.displayToastMessage(TOAST_STATE.success, response.responseMessage!, MAT_ICON.success);
                this.dismissError();
                this.closeDialog();
                window.location.reload();
            });
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }
    closeDialog() {
        this._matdialog.closeAll();
    }
}
