import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DoctorConsultingHour } from '../updatedoctor/doctorconsultinghour';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-addconsultinghourpopup.ts',
    templateUrl: './addconsultinghourpopup.ts.component.html',
    styleUrls: ['./addconsultinghourpopup.ts.component.scss']
})
export class AddconsultinghourpopupTsComponent implements OnInit {

    selectedAlign: 'start' | 'center' | 'end';

    consultationForm?: FormGroup;

    doctorConsultingHour: DoctorConsultingHour = {};

    doctorConsultingHoursList: DoctorConsultingHour[] = [];

    @ViewChild('startTimeInput') startTimeInput!: ElementRef;
    startTime: string | null = null;

    @ViewChild('endTimeInput') endTimeInput!: ElementRef;
    endTime: string | null = null;

    addConsultingHour = true;

    constructor(
        private _matdialog: MatDialog,
        private _fb: FormBuilder
    ) {

        this.selectedAlign = 'end';
        this.createConsultationHourForm()
    }

    ngOnInit(): void {
        if (sessionStorage.getItem('doctorConsultationList')) {
            this.doctorConsultingHoursList = JSON.parse(sessionStorage.getItem('doctorConsultationList') as string) as DoctorConsultingHour[];
        }
    }


    createConsultationHourForm() {
        this.consultationForm = this._fb.group({
            day: ['', [Validators.required]]
        });
    }


    collectConsultationHourData() {
        this.doctorConsultingHour = {
            day: this.consultationForm?.controls['day'].value as string | undefined,
            startTime: this.startTime!,
            endTime: this.endTime!

        };

    }

    addConsultation() {
        this.collectConsultationHourData();
        this.doctorConsultingHoursList.push(this.doctorConsultingHour);
        sessionStorage.setItem('doctorConsultationList', JSON.stringify(this.doctorConsultingHoursList));
        this.closeDialog();
    }

    closeDialog() {
        this._matdialog.closeAll();
    }

    onChangestartTime() {
        this.startTime = this.startTimeInput.nativeElement.value;
    }

    onChangeEndTime() {
        this.endTime = this.endTimeInput.nativeElement.value;
    }

    changeConsulting() {
        this.addConsultingHour = !this.addConsultingHour;
        this.startTime = null;
        this.endTime = null;
    }
}
