
import { UpdateexaminationpopupComponent } from './specialities/updatespeciality/updateexaminationpopup/updateexaminationpopup.component';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import localeHu from '@angular/common/locales/hu';
import localeHuExtra from '@angular/common/locales/extra/hu';
import { registerLocaleData } from '@angular/common';
import { environment } from 'src/environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import {
    BreadcrumbModule,
    FooterModule,
    HeadermoduleModule,
    IntroductionModule,
    LanguageModule,
    LogLevel,
    SharedModule,
    TranslationModule,
    SlideshowModule,
    NavigationModule,
    ToastmessageModule,
    BannersModule,
    HtmleditorModule,
    HomeConfigModule,
    BlogModule,
    FilemanagerModule,
    TinymcehtmleditorModule,
    FilterModule,
    ColleauguesModule,
    SiteconfigModule
} from '@prekog/pw-common';
import { MAT_RADIO_DEFAULT_OPTIONS, MatRadioModule } from '@angular/material/radio';
import { RouterModule } from '@angular/router';
import { GoogleMapsModule } from '@angular/google-maps';
import { HomeComponent } from './home/home.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PricelistComponent } from './pricelist/pricelist.component';
import { AddexaminationComponent } from './pricelist/addexamination/addexamination.component';
import { DoctorsComponent } from './doctors/doctors.component';
import { UpdatedoctorComponent } from './doctors/updatedoctor/updatedoctor.component';
import { AddconsultationhourComponent } from './doctors/addconsultationhour/addconsultationhour.component';
import { DeleteconsultinghourpopupComponent } from './doctors/deleteconsultinghourpopup/deleteconsultinghourpopup.component';
import { AdddoctorComponent } from './doctors/adddoctor/adddoctor.component';
import { AddjobtitleComponent } from './doctors/addjobtitle/addjobtitle.component';
import { AddconsultinghourpopupTsComponent } from './doctors/addconsultinghourpopup.ts/addconsultinghourpopup.ts.component';
import { AddspecialityComponent } from './specialities/addspeciality/addspeciality.component';
import { SpecialityPriceListComponent } from './specialitypricelist/specialitypricelist.component';
import { SpecialitiesComponent } from './specialities/specialities.component';
import { UpdatespecialityComponent } from './specialities/updatespeciality/updatespeciality.component';
import { AddexaminationpopupComponent } from './specialities/addspeciality/addexaminationpopup/addexaminationpopup.component';
import { DeletedoctorpopupComponent } from './doctors/deletedoctorpopup/deletedoctorpopup.component';
import { DeletespecialitypopupComponent } from './specialities/deletespecialitypopup/deletespecialitypopup.component';
import { DeletepricepopupComponent } from './pricelist/deletepricepopup/deletepricepopup.component';
import { DeletedoctorspecialitypopupComponent } from './specialities/updatespeciality/deletedoctorspecialitypopup/deletedoctorspecialitypopup.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DeletejobtitlepopupComponent } from './doctors/deletejobtitlepopup/deletejobtitlepopup.component';
import { SpecialitytypeComponent } from './specialitytype/specialitytype.component';
import { AddspecialitytypeComponent } from './specialitytype/addspecialitytype/addspecialitytype.component';
import { UpdatespecialitytypeComponent } from './specialitytype/updatespecialitytype/updatespecialitytype.component';

registerLocaleData(localeHu, 'hu-Hu', localeHuExtra);

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        PricelistComponent,
        SpecialityPriceListComponent,
        AddspecialityComponent,
        AddexaminationComponent,
        DoctorsComponent,
        UpdatedoctorComponent,
        AddconsultationhourComponent,
        DeleteconsultinghourpopupComponent,
        AdddoctorComponent,
        AddjobtitleComponent,
        AddconsultinghourpopupTsComponent,
        SpecialitiesComponent,
        UpdatespecialityComponent,
        AddexaminationpopupComponent,
        DeletedoctorpopupComponent,
        DeletespecialitypopupComponent,
        DeletepricepopupComponent,
        UpdateexaminationpopupComponent,
        DeletedoctorspecialitypopupComponent,
        DeletejobtitlepopupComponent,
        SpecialitytypeComponent,
        AddspecialitytypeComponent,
        UpdatespecialitytypeComponent,
    ],
    imports: [
        BrowserModule,
        RouterModule,
        ReactiveFormsModule,
        BrowserModule,
        MatToolbarModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatDividerModule,
        MatDialogModule,
        MatSelectModule,
        MatMenuModule,
        MatFormFieldModule,
        MatCheckboxModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        TranslationModule.forRoot({ translatedLanguages: ['hu'] }),
        LanguageModule.forRoot(),
        BreadcrumbModule.forRoot(),
        FooterModule,
        HeadermoduleModule,
        NavigationModule,
        AppRoutingModule,
        SharedModule.forRoot({ logLevel: LogLevel.Info, enableUseCaseStatusLogs: true }), //TODO: from environment.properties
        IntroductionModule.forRoot({ isRowView: false }),
        SlideshowModule,
        MatRadioModule,
        GoogleMapsModule,
        DragDropModule,
        ToastmessageModule,
        BannersModule,
        ClipboardModule,
        HtmleditorModule,
        HomeConfigModule,
        BlogModule,
        FilemanagerModule,
        TinymcehtmleditorModule,
        FilterModule,
        SiteconfigModule,
        ColleauguesModule

    ],
    providers: [
        { provide: MAT_DIALOG_DATA, useValue: {} },
        {
            provide: LOCALE_ID,
            useValue: 'hu-HU'
        },
        {
            provide: MAT_RADIO_DEFAULT_OPTIONS,
            useValue: { color: 'black' }
        },
        { provide: 'CONFIGURATOR_BASE_URL', useValue: environment.backendConfiguratorUrl },
        { provide: 'API_URL', useValue: environment.apiUrl },
        { provide: 'DOWNLOAD_FILE_URL', useValue: environment.downloadUrl },
        { provide: 'PRODUCTION', useValue: environment.production },
        { provide: 'REGENERO_BASE_URL', useValue: environment.regeneroBaseUrl },
        { provide: 'AUTH_BACKEND_URL', useValue: environment.backendAuthUrl },
        { provide: 'FILE_MANAGER_URL', useValue: environment.backendFileManagerUrl }
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent]
})
export class AppModule { }
