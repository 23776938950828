<div class="contact-content">
    <div class="back-button">
        <mat-icon routerLink="/doctors">arrow_back</mat-icon>
    </div>
    <div>
        <h3>ORVOS HOZZÁADÁSA</h3>
        <p>A * - al jelölt mezők kitöltése kötelező!</p>
    </div>
    <div class="contact-form">
        <form [formGroup]="doctorForm!" novalidate>
            <div class="field-container">
                <div class="field">
                    <b>Orvos neve *</b>
                    <div class="data">
                        <input [(ngModel)]="doctor.name" formControlName="name" id="name" class="form-control" type="text" required />
                    </div>
                </div>
            </div>

            <div class="field-container">
                <div class="field">
                    <b>Orvos foglalási linkje*</b>
                    <div class="data">
                        <input
                            [(ngModel)]="doctor.reservationLink"
                            formControlName="reservationLink"
                            id="reservationLink"
                            class="form-control"
                            type="text"
                            required
                        />
                    </div>
                </div>
            </div>
            <div class="field-container">
                <div class="field">
                    <b>Orvos url-e*</b>
                    <div class="data">
                        <div [ngStyle]="{ display: 'flex', width: '40%', 'justify-content': 'center' }">
                            <span><span>Pl.: dr_balo_timea</span></span>
                        </div>
                        <input
                            [(ngModel)]="doctor.routerLink"
                            formControlName="routerLink"
                            id="routerLink"
                            class="form-control"
                            type="text"
                            required
                        />
                    </div>
                </div>
            </div>

            <div class="field-container">
                <div class="field">
                    <b>Orvos bemutatkozó szövege *</b>
                    <div class="data">
                        <app-tinymcehtmleditor
                            [html]="doctor.introduction!"
                            [htmlForm]="htmlForm"
                            id="html"
                            class="form-control"
                            type="text"
                            required
                            ngDefaultControl
                        ></app-tinymcehtmleditor>
                    </div>
                </div>
            </div>
        </form>
        <div class="field-container">
            <div class="field">
                <b>Kép * (.jpg vagy .png)</b>
                <div class="data">
                    <div class="image-container">
                        <button [ngStyle]="{ background: 'lightgrey' }" onclick="document.getElementById('fileCover').click()">
                            Kép kiválasztása
                        </button>
                        <span *ngIf="uploadCoverFile">{{ fileCover?.name }}</span>
                        <mat-icon (click)="clearSelectedCoverFile()">delete_forever</mat-icon>
                        <input
                            [ngStyle]="{ display: 'none' }"
                            id="fileCover"
                            #fileCoverInput
                            class="form-control"
                            type="file"
                            (change)="onChange($event)"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="field-container">
            <div class="field">
                <b>Négyzetes kép * (.jpg vagy .png)</b>
                <div class="data">
                    <div class="image-container">
                        <button [ngStyle]="{ background: 'lightgrey' }" onclick="document.getElementById('fileCoverSquare').click()">
                            Kép kiválasztása
                        </button>
                        <span *ngIf="uploadCoverSquareFile">{{ fileCoverSquare?.name }}</span>
                        <mat-icon (click)="clearSelectedCoverSquareFile()">delete_forever</mat-icon>
                        <input
                            [ngStyle]="{ display: 'none' }"
                            id="fileCoverSquare"
                            #fileCoverSquareInput
                            class="form-control"
                            type="file"
                            (change)="onChangeSquare($event)"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="contact-form">
        <h3>Orvos munkakörei *</h3>
        <mat-icon routerLink="/addspeciality" [ngStyle]="{ 'margin-bottom': '8px', cursor: 'pointer' }">add_circle</mat-icon>
        <div class="field-container">
            <div class="field">
                <div [ngStyle]="{ 'flex-direction': 'row', width: '100%', 'flex-wrap': 'wrap' }" class="data">
                    <section *ngFor="let speciality of specialityWithCheckedList" class="example-section">
                        <mat-checkbox (click)="changeJobTitle(speciality)" class="example-margin">
                            {{ speciality.speciality?.jobTitle }}</mat-checkbox
                        >
                    </section>
                </div>
            </div>
        </div>
    </div>
    <div class="contact-form">
        <h3>Konzultációs idő hozzáadása *</h3>
        <mat-icon (click)="openDeleteAddConsultingHourPopup()" [ngStyle]="{ 'margin-bottom': '8px', cursor: 'pointer' }"
            >add_circle</mat-icon
        >
        <div *ngFor="let consultationHour of doctorConsultingHoursList; let i = index" class="contact-form">
            <div [ngStyle]="{ display: 'flex', width: '100%' }">
                <div class="field-container">
                    <div class="field">
                        <b [ngStyle]="{ width: '100%' }">Konzultáció napja:</b>
                        <div class="data">
                            <span>{{ consultationHour.day }}</span>
                        </div>
                    </div>
                </div>
                <div class="field-container">
                    <div class="field">
                        <b [ngStyle]="{ width: '100%' }">Konzultáció kezdete:</b>
                        <div class="data">
                            <span *ngIf="consultationHour.startTime !== null">{{ consultationHour.startTime }}</span>
                            <span *ngIf="consultationHour.startTime === null">változó</span>
                        </div>
                    </div>
                </div>
                <div class="field-container">
                    <div class="field">
                        <b [ngStyle]="{ width: '100%' }">Konzultáció vége:</b>
                        <div class="data">
                            <span *ngIf="consultationHour.endTime !== null">{{ consultationHour.endTime }}</span>
                            <span *ngIf="consultationHour.endTime === null">változó</span>
                        </div>
                    </div>
                    <mat-icon (click)="removeConsultingHour(i)" [ngStyle]="{ cursor: 'pointer' }">delete_forever</mat-icon>
                </div>
            </div>
        </div>
    </div>

    <div [ngStyle]="{ 'margin-top': '8px' }" class="field-container">
        <div [ngStyle]="{ 'justify-content': 'center', 'margin-top': '8px', 'margin-bottom': '8px' }" class="field">
            <div [ngStyle]="{ 'justify-content': 'center' }" class="data">
                <button
                    (click)="addDoctor()"
                    [ngStyle]="{ background: '#eee', border: '1px solid #0E8388', 'font-weight': 'bold', width: '100%' }"
                    [disabled]="!doctorForm?.valid || !htmlForm?.valid"
                >
                    ORVOS MENTÉSE
                </button>
            </div>
        </div>
    </div>
</div>
