import { Component, Inject, OnInit } from '@angular/core';
import { ApiRequestService, HttpError, MAT_ICON, MatdialogService, TOAST_STATE, ToastmessageService } from '@prekog/pw-common';
import { Router } from '@angular/router';
import { catchError, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Speciality } from '../specialitypricelist/speciality';
import { GetSpecialitiesResponse } from '../specialitypricelist/getspecialitiesresponse';
import { DeleteSpecialityRequest } from './deletespecialityrequest';
import { DeletespecialitypopupComponent } from './deletespecialitypopup/deletespecialitypopup.component';

@Component({
    selector: 'pw-specialities',
    templateUrl: './specialities.component.html',
    styleUrls: ['./specialities.component.scss']
})
export class SpecialitiesComponent implements OnInit {

    getSpecialitiesUrl = '/regenero/get-all-specialities';

    specialities?: Speciality[];

    regeneroBaseUrl = '';

    searchText = '';

    deleteSpecialityUrl = '/admin/delete-speciality';

    constructor(
        public _router: Router,
        private _apiRequestService: ApiRequestService,
        @Inject('REGENERO_BASE_URL') private _regeneroBaseUrl: string,
        private _toastMessageService: ToastmessageService,
        private _matDialogService:MatdialogService
    ) {

        this.regeneroBaseUrl = _regeneroBaseUrl;
    }
    ngOnInit(): void {
        if (!sessionStorage.getItem('token')) {
            this._router.navigateByUrl('/');
        }
        else {
            this.getSpecialities();
        }
    }

    getSpecialities() {
        this._apiRequestService
            .sendRequest<{}, GetSpecialitiesResponse>(this.regeneroBaseUrl, true, {}, true, this.getSpecialitiesUrl)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(TOAST_STATE.error, 'HttpError hiba!', MAT_ICON.error);
                        this.dismissError();


                    });
                })
            )
            .subscribe((response: GetSpecialitiesResponse) => {
                this.specialities = response.specialityList;
            });
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }

    openDeleteSpecialityPopup(speciality:Speciality) {
        const doctorIdList:Array<number|undefined> = Object.values(speciality.doctorSpecialityList!).map(doctorSpeciality => doctorSpeciality.doctor?.id);

        const deleteSpecialityRequest:DeleteSpecialityRequest = {
            specialityId:speciality.id,
            doctorIdList:doctorIdList
        }

        this._matDialogService.openPopupWithData(deleteSpecialityRequest,DeletespecialitypopupComponent);
    }

}
