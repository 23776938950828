<div class="specialities">
    <div class="title">
        <div class="tit">
            <h3>ÁRLISTA MÓDOSÍTÁSA</h3>
            <!-- <div class="add-icon">
                <mat-icon routerLink="/addspeciality">add_circle</mat-icon>
            </div> -->
        </div>
    </div>

    <div class="add-new-speciality">
        <div class="search-bar">
            <div class="search-tool">
                <input [(ngModel)]="searchText" type="search" placeholder="Szakterület neve" />
                <div class="icon">
                    <mat-icon aria-hidden="false" aria-label="keyboard_arrow_right icon" fontIcon="search"></mat-icon>
                </div>
            </div>
        </div>
    </div>
    <div class="speciality-container">
        <div class="speciality-c">
            <div *ngFor="let speciality of specialities | filter : searchText; let i = index" class="speciality">
                <div class="pro">
                    <div
                        [ngStyle]="
                            i % 2 === 0 ? { background: 'white', border: '1px solid #ccc' } : { background: '#efeff5', border: 'black' }
                        "
                        class="name"
                    >
                        <div
                            [ngStyle]="i % 2 === 0 ? { background: 'white' } : { background: '#efeff5' }"
                            class="button"
                            routerLink="/pricelist/{{ speciality.id }}"
                            *ngIf="speciality.examinationList?.length! > 0"
                        >
                            {{ speciality.name }} árlista módosítása
                        </div>
                        <div
                            [ngStyle]="i % 2 === 0 ? { background: 'white' } : { background: '#efeff5' }"
                            class="button"
                            *ngIf="speciality.examinationList?.length! === 0"
                        >
                            <b>{{ speciality.name }} árlista (nincs benne tétel)</b>
                        </div>
                        <div [ngStyle]="{ 'align-self': 'center', 'padding-left': '8px', 'padding-right': '8px' }" class="add-icon">
                            <!-- <button
                                [ngStyle]="{ cursor: 'pointer', border: 'none', background: 'none' }"
                                routerLink="/addexamination/{{ speciality.id }}"
                            >
                                Tétel hozzáadása
                            </button> -->
                            <mat-icon
                                [ngStyle]="{ cursor: 'pointer', border: 'none', background: 'none' }"
                                routerLink="/addexamination/{{ speciality.id }}"
                                >add_circle</mat-icon
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
