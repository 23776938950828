import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ApiRequestService, HttpError, MAT_ICON, TOAST_STATE, ToastmessageService } from '@prekog/pw-common';
import { catchError, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { DeleteSpecialityRequest } from '../deletespecialityrequest';
import { DeleteSpecialityResponse } from '../deletespecialityresponse';

@Component({
  selector: 'app-deletespecialitypopup',
  templateUrl: './deletespecialitypopup.component.html',
  styleUrls: ['./deletespecialitypopup.component.scss']
})
export class DeletespecialitypopupComponent {

    deletSpecialityUrl = '/admin/delete-speciality';

    regeneroBaseUrl = '';

    selectedAlign: 'start' | 'center' | 'end';

    constructor(
        private _toastMessageService: ToastmessageService,
        private _apiRequestService: ApiRequestService,
        @Inject('REGENERO_BASE_URL') private _regeneroBaseUrl: string,
        private _matdialog:MatDialog,
        @Inject(MAT_DIALOG_DATA) public deleteSpecialityRequest: DeleteSpecialityRequest
    ) {
        this.regeneroBaseUrl = _regeneroBaseUrl;

        this.selectedAlign = 'end';
    }

      //TODO confirm popup!!!
      deleteBanner() {

        this._apiRequestService
            .sendRequest<DeleteSpecialityRequest, DeleteSpecialityResponse>(
                this.regeneroBaseUrl,
                true,
                this.deleteSpecialityRequest,
                true,
                this.deletSpecialityUrl
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(
                            TOAST_STATE.error,
                            'Sikertelen törlés' + httpError.errorMessage,
                            MAT_ICON.error
                        );
                        this.dismissError();
                    });
                })
            )
            .subscribe((response) => {
                this._toastMessageService.displayToastMessage(TOAST_STATE.success, response.responseMessage!, MAT_ICON.success);
                this.dismissError();
                this.closeDialog();
                window.location.reload();
            });
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }
    closeDialog() {
        this._matdialog.closeAll();
    }

}
