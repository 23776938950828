import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Speciality } from './speciality';
import { Router } from '@angular/router';
import { ApiRequestService, HttpError, MAT_ICON, TOAST_STATE, ToastmessageService, TranslationService } from '@prekog/pw-common';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import { FindDoctorRequest } from '../updatedoctor/finddoctorrequest';
import { FindDoctorResponse } from '../updatedoctor/finddoctorresponse';
import { AddJobTitleRequest } from './addjobtitlerequest';
import { AddJobTitleResponse } from './addjobtitleresponse';

@Component({
    selector: 'app-addjobtitle',
    templateUrl: './addjobtitle.component.html',
    styleUrls: ['./addjobtitle.component.scss']
})
export class AddjobtitleComponent implements OnInit {

    addJobTitleUrl = '/admin/add-job-title';

    findDoctorUrl = '/regenero/find-doctor';

    doctorName = '';

    specialityForm?: FormGroup;

    speciality: Speciality = {};

    regeneroBaseUrl = '';

    id = 0;

    constructor(
        public _router: Router,
        private _apiRequestService: ApiRequestService,
        @Inject('REGENERO_BASE_URL') private _regeneroBaseUrl: string,
        private _toastMessageService: ToastmessageService,
        private _fb: FormBuilder,
        private _translateService: TranslationService,

    ) {
        this.regeneroBaseUrl = _regeneroBaseUrl;

        this.createSpecialityForm();

    }

    ngOnInit(): void {
        if (!sessionStorage.getItem('token')) {
            this._router.navigateByUrl('/');
        }
        else {
            this.findDoctor();
        }
    }

    findDoctor() {
        const lastSegmentOfUrl = window.location.href.split('/').pop();
        const routerLink = lastSegmentOfUrl as string;
        this.id = +routerLink;
        const findDoctorRequest: FindDoctorRequest = {
            id: this.id
        };

        this._apiRequestService
            .sendRequest<FindDoctorRequest, FindDoctorResponse>(this.regeneroBaseUrl, true, findDoctorRequest, true, this.findDoctorUrl)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(TOAST_STATE.error, 'HttpError hiba!', MAT_ICON.error);
                        this.dismissError();


                    });
                })
            )
            .subscribe((response: FindDoctorResponse) => {
                this.doctorName = response.doctor?.name!;


            });
    }

    createSpecialityForm() {
        this.specialityForm = this._fb.group({
            name: ['', [Validators.required]],
            jobTitle: ['', [Validators.required]]

        });
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }

    collectSpecialityData() {
        this.speciality = {
            name: this.specialityForm?.controls['name'].value as string | undefined,
            jobTitle: this.specialityForm?.controls['jobTitle'].value as string | undefined,
            isSpeciality: true

        };

    }

    addJobTitle() {
        this.collectSpecialityData();
        const addJobTitleRequest: AddJobTitleRequest = {
            speciality: this.speciality,
            doctorId: this.id
        }

        this._apiRequestService
            .sendRequest<AddJobTitleRequest, AddJobTitleResponse>(this.regeneroBaseUrl, true, addJobTitleRequest, true, this.addJobTitleUrl)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(TOAST_STATE.error, 'HttpError hiba!', MAT_ICON.error);
                        this.dismissError();

                        this._router.navigateByUrl('/');
                    });
                })
            )
            .subscribe((response: AddJobTitleResponse) => {
                this._toastMessageService.displayToastMessage(TOAST_STATE.success, response.responseMessage!, MAT_ICON.success);
                this.dismissError();
                this._router.navigateByUrl('/doctor/' + this.id);


            });
    }

}
