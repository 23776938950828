import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiRequestService, HttpError, MAT_ICON, TOAST_STATE, ToastmessageService, TranslationService } from '@prekog/pw-common';
import { SpecialityType } from '../../specialities/addspeciality/specialitytype';
import { AddSpecialityTypeRequest } from './addspecialitytyperequest';
import { AddSpecialityTypeResponse } from './addspecialitytyperesponse';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';

@Component({
    selector: 'app-addspecialitytype',
    templateUrl: './addspecialitytype.component.html',
    styleUrls: ['./addspecialitytype.component.scss']
})
export class AddspecialitytypeComponent {

    specialityTypeForm?: FormGroup;

    regeneroBaseUrl = '';

    specialityType: SpecialityType = {};

    addSpecialityTypeUrl = '/admin/add-specialitytype';

    constructor(
        public _router: Router,
        private _apiRequestService: ApiRequestService,
        @Inject('REGENERO_BASE_URL') private _regeneroBaseUrl: string,
        private _toastMessageService: ToastmessageService,
        private _fb: FormBuilder,
        private _translateService: TranslationService,
    ) {
        this.regeneroBaseUrl = _regeneroBaseUrl;

        this.createSpecialityTypeForm();
    }

    createSpecialityTypeForm() {
        this.specialityTypeForm = this._fb.group({
            name: ['', [Validators.required]],
            jobTitle: ['', [Validators.required]]


        });
    }

    collectSpecialityTypeData() {
        this.specialityType = {
            name: this.specialityTypeForm?.controls['name'].value as string | undefined,
            jobTitle: this.specialityTypeForm?.controls['jobTitle'].value as string | undefined,
        };
    }

    addSpecialityType() {
        this.collectSpecialityTypeData();

        const addSpecialityTypeRequest: AddSpecialityTypeRequest = {
            specialityType: this.specialityType,
        }

        this._apiRequestService
            .sendRequest<AddSpecialityTypeRequest, AddSpecialityTypeResponse>(this.regeneroBaseUrl, true, addSpecialityTypeRequest, true, this.addSpecialityTypeUrl)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;
                        if (error.status === 422) {
                            alert(this._translateService.translate(httpError.errorMessage!));
                        } else {
                            alert(this._translateService.translate('error.http'));
                        }
                    });
                })
            )
            .subscribe((response: AddSpecialityTypeResponse) => {
                this._toastMessageService.displayToastMessage(TOAST_STATE.success, response.responseMessage!, MAT_ICON.success);
                this.dismissError();
            });
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }


}
