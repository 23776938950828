import { Component, Inject, OnInit } from '@angular/core';
import { ApiRequestService, HttpError, MAT_ICON, TOAST_STATE, ToastmessageService } from '@prekog/pw-common';
import { Speciality } from './speciality';
import { Router } from '@angular/router';
import { GetSpecialitiesResponse } from './getspecialitiesresponse';
import { catchError, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Examination } from '../pricelist/examination';

@Component({
    selector: 'app-specialities',
    templateUrl: './specialitypricelist.component.html',
    styleUrls: ['./specialitypricelist.component.scss']
})
export class SpecialityPriceListComponent implements OnInit {

    getSpecialitiesUrl = '/regenero/get-all-specialities';

    specialities?: Speciality[];

    regeneroBaseUrl = '';

    examinationList: Examination[] = [];

    searchText = '';

    constructor(
        public _router: Router,
        private _apiRequestService: ApiRequestService,
        @Inject('REGENERO_BASE_URL') private _regeneroBaseUrl: string,
        private _toastMessageService: ToastmessageService
    ) {

        this.regeneroBaseUrl = _regeneroBaseUrl;
    }
    ngOnInit(): void {
        if (!sessionStorage.getItem('token')) {
            this._router.navigateByUrl('/');
        }
        else {
            this.getSpecialities();
        }
    }

    getSpecialities() {
        this._apiRequestService
            .sendRequest<{}, GetSpecialitiesResponse>(this.regeneroBaseUrl, true, {}, true, this.getSpecialitiesUrl)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(TOAST_STATE.error, 'HttpError hiba!', MAT_ICON.error);
                        this.dismissError();


                    });
                })
            )
            .subscribe((response: GetSpecialitiesResponse) => {
                this.specialities = response.specialityList;
            });
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }
}
