import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { Doctor } from '../doctor';
import { Router } from '@angular/router';
import { ApiRequestService, HttpError, MAT_ICON, MatdialogService, TOAST_STATE, ToastmessageService, TranslationService } from '@prekog/pw-common';
import { DoctorConsultingHour } from './doctorconsultinghour';
import { FindDoctorResponse } from './finddoctorresponse';
import { catchError, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UpdateDoctorRequest } from './updatedoctorrequest';
import { UpdateDoctorResponse } from './updatedoctorresponse';
import { UploadFileResponse } from './uploadfileresponse';
import { FindDoctorRequest } from './finddoctorrequest';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { DeleteconsultinghourpopupComponent } from '../deleteconsultinghourpopup/deleteconsultinghourpopup.component';
import { Observable } from 'rxjs';
import { Speciality } from '../../specialitypricelist/speciality';
import { environment } from '../../../environments/environment';
import { SpecialityWithChecked } from '../adddoctor/specialitywithchecked';
import { GetSpecialitiesResponse } from 'src/app/specialitypricelist/getspecialitiesresponse';
import { DeletedoctorspecialitypopupComponent } from 'src/app/specialities/updatespeciality/deletedoctorspecialitypopup/deletedoctorspecialitypopup.component';
import { DoctorJobTitle } from '../deletejobtitlepopup/doctorjobtitle';
import { DeletejobtitlepopupComponent } from '../deletejobtitlepopup/deletejobtitlepopup.component';



@Component({
    selector: 'app-updatedoctor',
    templateUrl: './updatedoctor.component.html',
    styleUrls: ['./updatedoctor.component.scss']
})
export class UpdatedoctorComponent implements OnInit {

    doctorForm?: FormGroup;

    doctor: Doctor = {};

    updateDoctorUrl = '/admin/update-doctor';

    findDoctorUrl = '/regenero/find-doctor';

    regeneroBaseUrl = '';

    downloadFileUrl = '';

    id = 0;

    doctorConsultingHoursList: DoctorConsultingHour[] = [];

    doctorImage = '';

    @ViewChild('fileCoverInput') fileCoverInput!: ElementRef;
    fileCover: File | null = null;

    @ViewChild('fileCoverSquareInput') fileCoverSquareInput!: ElementRef;
    fileCoverSquare: File | null = null;

    uploadCoverSquareFile = false;

    updateCoverFile = false;

    consultingHourForm?: FormGroup;

    uploadFileUrl = '/admin/uploadFile';

    deleteContentOfFolderUrl = '/admin/deletefoldercontent';

    fileBaseUrl = '';

    uploadFileToAnExistingFolderUrl = '/admin/updatefile';

    maxFileSize = 5000000;

    html = '';

    htmlForm?: FormGroup;

    doctorSpecialityList?: Speciality[];

    baseRegeneroUrl = '';

    specialityWithCheckedList?: SpecialityWithChecked[];

    getSpecialitiesUrl = '/regenero/get-all-specialities';

    get items() {
        return this.consultingHourForm?.get('items') as FormArray;
    }

    constructor(
        public _router: Router,
        private _apiRequestService: ApiRequestService,
        @Inject('DOWNLOAD_FILE_URL') private _downloadFileUrl: string,
        @Inject('REGENERO_BASE_URL') private _regeneroBaseUrl: string,
        private _toastMessageService: ToastmessageService,
        private _fb: FormBuilder,
        @Inject('FILE_MANAGER_URL') private _fileManagerUrl: string,
        private _translateService: TranslationService,
        private _matDialogService: MatdialogService
    ) {
        this.downloadFileUrl = _downloadFileUrl;
        this.regeneroBaseUrl = _regeneroBaseUrl;
        this.fileBaseUrl = _fileManagerUrl;
        this.baseRegeneroUrl = environment.apiUrlRegenero;
    }

    ngOnInit(): void {
        if (!sessionStorage.getItem('token')) {
            this._router.navigateByUrl('/');
        }
        else {
            this.findDoctor();
            this.createDoctorForm();
            this.createConsultingHourFormArray();
            this.createHtmlForm();
        }

    }

    createHtmlForm() {
        this.htmlForm = this._fb.group({
            html: ['', [Validators.required]],

        });
    }

    findDoctor() {
        const lastSegmentOfUrl = window.location.href.split('/').pop();
        const routerLink = lastSegmentOfUrl as string;
        this.id = +routerLink;
        const findDoctorRequest: FindDoctorRequest = {
            id: this.id
        };

        this._apiRequestService
            .sendRequest<FindDoctorRequest, FindDoctorResponse>(this.regeneroBaseUrl, true, findDoctorRequest, true, this.findDoctorUrl)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(TOAST_STATE.error, 'HttpError hiba!', MAT_ICON.error);
                        this.dismissError();


                    });
                })
            )
            .subscribe((response: FindDoctorResponse) => {
                this.doctor = response.doctor!;
                this.doctorConsultingHoursList = [...this.doctor.doctorConsultingHourList!];
                this.consultingHourForm = this._fb.group({
                    items: this._fb.array(this.doctorConsultingHoursList!.map(consultingHours => this._fb.group(consultingHours)))
                });

                this.getSpecialities();


            });
    }


    openDeleteConsultingPopup(id: number) {
        this._matDialogService.openPopupWithData(id, DeleteconsultinghourpopupComponent);
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }

    createDoctorForm() {
        this.doctorForm = this._fb.group({
            name: ['', [Validators.required]],
            reservationLink: ['', [Validators.required]],
            routerLink: ['', [Validators.required]]

        });
    }

    collectDoctorData() {
        const doctorId = this.doctor.id!;
        if (!this.doctorImage) {
            this.doctorImage = this.doctor.image!
        }
        this.doctor = {
            id: doctorId,
            name: this.doctorForm?.controls['name'].value as string | undefined,
            introduction: this.htmlForm?.controls['html'].value as string | undefined,
            reservationLink: this.doctorForm?.controls['reservationLink'].value as string | undefined,
            image: this.doctorImage,
            routerLink: this.doctorForm?.controls['routerLink'].value as string | undefined


        };
    }

    createConsultingHourFormArray() {
        this.consultingHourForm = this._fb.group({
            items: this._fb.array([this.consultingHourForm])
        });
    }

    updateDoctorPart() {
        this.collectDoctorData();

        const updateDoctorRequest: UpdateDoctorRequest = {
            doctor: this.doctor,
            consultingHourList: this.items.value as DoctorConsultingHour[],
            jobTitleList: this.collectJobTitles()
        }

        this._apiRequestService
            .sendRequest<UpdateDoctorRequest, UpdateDoctorResponse>(this.regeneroBaseUrl, true, updateDoctorRequest, true, this.updateDoctorUrl)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(TOAST_STATE.error, 'HttpError hiba!', MAT_ICON.error);
                        this.dismissError();

                        this._router.navigateByUrl('/');
                    });
                })
            )
            .subscribe((response: UpdateDoctorResponse) => {
                this._toastMessageService.displayToastMessage(TOAST_STATE.success, response.responseMessage!, MAT_ICON.success);
                this.dismissError();
                window.location.reload();

            });
    }

    clearSelectedCoverFile() {
        this.fileCoverInput.nativeElement.value = null;
        this.updateCoverFile = false;
    }

    onChange(event: Event) {
        const target = event.target as HTMLInputElement;
        this.fileCover = target.files![0];
        if (this.fileCover.name.split('.').pop() === 'jpg' || 'png' || 'JPEG' || 'jpeg') {
            this.updateCoverFile = true;
        }
        else {
            this.updateCoverFile = false;
            this._toastMessageService.displayToastMessage(TOAST_STATE.warning, 'Kérem ".jpg" vagy ".png"+ kiterjesztésű fájlt töltsön fel!', MAT_ICON.warning);
            this.dismissError();
        }

    }

    updateDoctor() {
        if (this.fileCover !== null && this.fileCover.size < this.maxFileSize) {
            if (this.fileCover?.size! > this.maxFileSize) {
                this._toastMessageService.displayToastMessage(
                    TOAST_STATE.error,
                    this._translateService.translate('A maximum fájl korlát 5MB. Kérem ennél kisebb méretű fájlt töltsön fel!'),
                    MAT_ICON.error
                );
                this.dismissError();
                return;
            }
            else {
                const folderName = this.doctor.image!.split('/').pop() as string;

                this.updateFile(this.fileCover, folderName);
            }

        }
        if (this.fileCoverSquare !== null && this.fileCoverSquare.size < this.maxFileSize) {
            if (this.fileCoverSquare?.size! > this.maxFileSize) {
                this._toastMessageService.displayToastMessage(
                    TOAST_STATE.error,
                    this._translateService.translate('A maximum fájl korlát 5MB. Kérem ennél kisebb méretű fájlt töltsön fel!'),
                    MAT_ICON.error
                );
                this.dismissError();
                return;
            }
            else {
                const folderName = this.doctor.thumbnailUrl!.split('/').pop() as string;

                this.updateFile(this.fileCoverSquare, folderName);
            }
        }
        else {
            this.updateDoctorPart();
        }

    }

    updateFile(file: File, folderName: string) {
        this._apiRequestService.updateFile(file, folderName, true, true, this.fileBaseUrl, this.uploadFileToAnExistingFolderUrl)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(TOAST_STATE.error, this._translateService.translate('admin.unsuccessfulSave! ' + httpError.errorMessage), MAT_ICON.error);
                        this.dismissError();


                        this._router.navigateByUrl('/');
                    });
                })
            )
            .subscribe((response) => {
                const uploadFileResponse = response as UploadFileResponse;
                this.doctorImage = uploadFileResponse.downloadUri as string;
                if (uploadFileResponse.downloadUri) {
                    this._toastMessageService.displayToastMessage(
                        TOAST_STATE.success,
                        'Sikeres fájl feltöltés ' + uploadFileResponse.fileName!,
                        MAT_ICON.success
                    );
                    this.dismissError();

                    this.updateCoverFile = false;
                    this.updateDoctorPart();
                }

            });
    }

    addConsultationHour() {
        this._router.navigateByUrl('/addconsultationhour/' + this.id)
    }

    changeJobTitle(specialityWith: SpecialityWithChecked) {
        this.specialityWithCheckedList?.forEach((specialityWithCheck => {
            if (specialityWith.speciality?.jobTitle === specialityWithCheck.speciality?.jobTitle) {
                specialityWithCheck.checked = !specialityWithCheck.checked
            }
        }))
    }


    getSpecialities() {
        this._apiRequestService
            .sendRequest<{}, GetSpecialitiesResponse>(this.regeneroBaseUrl, true, {}, true, this.getSpecialitiesUrl)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(TOAST_STATE.error, 'HttpError hiba!', MAT_ICON.error);
                        this.dismissError();

                        this._router.navigateByUrl('/');
                    });
                })
            )
            .subscribe((response: GetSpecialitiesResponse) => {
                const specilities: Speciality[] = response.specialityList?.filter(d => !this.doctor.doctorSpecialityList?.some(d2 => d2.speciality?.jobTitle === d.jobTitle)) as Speciality[];
                const specialityWithCheckedList: SpecialityWithChecked[] = [];
                specilities.forEach((speciality) => {
                    if (!speciality.isSpeciality) {
                        const index = specilities.indexOf(speciality);
                        specilities.splice(index!, 1);
                    }
                    else {
                        const specialityWithChecked: SpecialityWithChecked = {
                            speciality: speciality,
                            checked: false
                        }

                        specialityWithCheckedList.push(specialityWithChecked)

                    }
                    this.specialityWithCheckedList = [...specialityWithCheckedList];



                })


            });
    }

    collectJobTitles(): Array<string> {
        let jobTitleList: Array<string> = [];

        this.specialityWithCheckedList?.forEach((specialityWithChecked) => {
            if (specialityWithChecked.checked) {
                jobTitleList.push(specialityWithChecked.speciality?.jobTitle!);
            }
        })

        return jobTitleList;
    }


    onChangeSquare(event: Event) {
        const target = event.target as HTMLInputElement;
        this.fileCoverSquare = target.files![0];
        if (this.fileCoverSquare.name.split('.').pop() === 'jpg' || 'png' || 'JPEG' || 'jpeg') {
            this.uploadCoverSquareFile = true;
        }
        else {
            this.uploadCoverSquareFile = false;
            this._toastMessageService.displayToastMessage(TOAST_STATE.warning, 'Kérem ".jpg" vagy ".png" kiterjesztésű fájlt töltsön fel!', MAT_ICON.warning);
            this.dismissError();
        }
    }

    clearSelectedCoverSquareFile() {
        this.fileCoverSquareInput.nativeElement.value = null;
        this.uploadCoverSquareFile = false;
    }

    openDeleteDoctorSpecialityPopup(doctorName: string, jobTitle: string, doctorSpecialityId?: number) {

        const doctorJobtitle: DoctorJobTitle = {
            doctorName: doctorName,
            jobTitle: jobTitle,
            doctorSpecialityId: doctorSpecialityId
        }

        this._matDialogService.openPopupWithData(doctorJobtitle, DeletejobtitlepopupComponent);

    }




}
