import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiRequestService, HttpError, MAT_ICON, TOAST_STATE, ToastmessageService, TranslationService } from '@prekog/pw-common';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import { Observable } from 'rxjs';
import { SpecialityType } from '../../specialities/addspeciality/specialitytype';
import { UpdateSpecialityTypeRequest } from './updatespecialitytyperequest';
import { UpdateSpecialityTypeResponse } from './updatespecialitytyperesponse';
import { FindSpecialityTypeByIdRequest } from './findspecialitytypebyidrequest';
import { FindSpecialityTypeByIdResponse } from './findspecialitytypebyidresponse';

@Component({
    selector: 'app-updatespecialitytype',
    templateUrl: './updatespecialitytype.component.html',
    styleUrls: ['./updatespecialitytype.component.scss']
})
export class UpdatespecialitytypeComponent implements OnInit {

    specialityTypeForm?: FormGroup;

    regeneroBaseUrl: string;

    specialityType: SpecialityType = {};

    updateSpecialityTypeUrl = '/admin/update-specialitytype';

    findSpecialityTypeByIdUrl = '/admin/find-specialitytype';


    constructor(
        public _router: Router,
        private _apiRequestService: ApiRequestService,
        @Inject('REGENERO_BASE_URL') private _regeneroBaseUrl: string,
        private _toastMessageService: ToastmessageService,
        private _fb: FormBuilder,
        private _translateService: TranslationService,
    ) {
        this.regeneroBaseUrl = _regeneroBaseUrl;

        this.createSpecialityTypeForm();

    }

    ngOnInit(): void {
        this.findSpecialityType();
    }

    findSpecialityType() {
        const lastSegmentOfUrl = window.location.href.split('/').pop();
        const routerLink = lastSegmentOfUrl as string;
        const specialityTypeId = +routerLink;
        const findSpecialityTypeByIdRequest: FindSpecialityTypeByIdRequest = {
            specialityTypeId: specialityTypeId
        };

        this._apiRequestService
            .sendRequest<FindSpecialityTypeByIdRequest, FindSpecialityTypeByIdResponse>(this.regeneroBaseUrl, true, findSpecialityTypeByIdRequest, true, this.findSpecialityTypeByIdUrl)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(TOAST_STATE.error, 'HttpError hiba!', MAT_ICON.error);
                        this.dismissError();


                    });
                })
            )
            .subscribe((response: FindSpecialityTypeByIdResponse) => {
                this.specialityType = response.specialityType!;

            });

    }

    createSpecialityTypeForm() {
        this.specialityTypeForm = this._fb.group({
            name: ['', [Validators.required]],
            jobTitle: ['', [Validators.required]]


        });
    }

    collectSpecialityData() {
        this.specialityType = {
            name: this.specialityTypeForm?.controls['name'].value as string | undefined,
            jobTitle: this.specialityTypeForm?.controls['jobTitle'].value as string | undefined,
        };
    }

    updateSpecialityType() {
        const updateSpecialityTypeRequest: UpdateSpecialityTypeRequest = {
            specialityType: this.specialityType,
        }

        this._apiRequestService
            .sendRequest<UpdateSpecialityTypeRequest, UpdateSpecialityTypeResponse>(this.regeneroBaseUrl, true, updateSpecialityTypeRequest, true, this.updateSpecialityTypeUrl)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;
                        if (error.status === 422) {
                            alert(this._translateService.translate(httpError.errorMessage!));
                        } else {
                            alert(this._translateService.translate('error.http'));
                        }
                    });
                })
            )
            .subscribe((response: UpdateSpecialityTypeResponse) => {
                this._toastMessageService.displayToastMessage(TOAST_STATE.success, response.responseMessage!, MAT_ICON.success);
                this.dismissError();

            });
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }

}
