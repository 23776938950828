<div class="contact-content">
    <div class="back-button">
        <mat-icon routerLink="/specialitypricelist">arrow_back</mat-icon>
    </div>
    <div>
        <h3>ÚJ TÉTEL HOZZÁADÁSA ({{ specialityName | uppercase }})</h3>
    </div>
    <div class="contact-form">
        <form [formGroup]="examinationForm!" novalidate>
            <div class="field-container">
                <div class="field">
                    <span>Tétel neve</span>
                    <div class="data">
                        <textarea
                            [(ngModel)]="examination.name"
                            formControlName="name"
                            id="name"
                            class="form-control"
                            type="text"
                            required
                        ></textarea>
                        <!-- <input [(ngModel)]="examination.name" formControlName="name" id="name" class="form-control" type="text" required /> -->
                    </div>
                </div>
            </div>
            <div *ngIf="!showMinMaxPrice" class="field-container">
                <div class="field">
                    <span>Tétel ára</span>
                    <div class="data">
                        <input
                            #priceInput
                            [ngModel]="price"
                            (change)="onChangePrice()"
                            id="price"
                            class="form-control"
                            type="number"
                            required
                        />
                    </div>
                </div>
            </div>

            <div (click)="showMaxMinPrices()" *ngIf="!showMinMaxPrice" class="field-container">
                <div class="field">
                    <div class="min">
                        <button><b>Minimum - maximum ár hozzáadása</b></button>
                    </div>
                </div>
            </div>

            <div *ngIf="showMinMaxPrice" class="field-container">
                <div class="field">
                    <span>Tétel minimum ára</span>
                    <div class="data">
                        <input
                            #minPriceInput
                            [ngModel]="minPrice"
                            (change)="onChangeMinPrice()"
                            id="price"
                            class="form-control"
                            type="number"
                            required
                        />
                    </div>
                </div>
            </div>

            <div *ngIf="showMinMaxPrice" class="field-container">
                <div class="field">
                    <span>Tétel maximum ára</span>
                    <div class="data">
                        <input
                            #maxPriceInput
                            [ngModel]="maxPrice"
                            (change)="onChangeMaxPrice()"
                            id="price"
                            class="form-control"
                            type="number"
                            required
                        />
                    </div>
                </div>
            </div>
            <div (click)="showPrice()" *ngIf="showMinMaxPrice" class="field-container">
                <div class="field">
                    <div class="min">
                        <button><b>Minimum - maximum ár elrejtése</b></button>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div [ngStyle]="{ 'margin-top': '8px' }" class="field-container">
        <div [ngStyle]="{ 'justify-content': 'center', 'margin-top': '8px', 'margin-bottom': '8px' }" class="field">
            <div [ngStyle]="{ 'justify-content': 'center' }" class="data">
                <button
                    (click)="addExamination()"
                    [ngStyle]="{ background: '#eee', border: '1px solid #0E8388', 'font-weight': 'bold', width: '100%' }"
                    [disabled]="!examinationForm?.valid"
                >
                    TÉTEL MENTÉSE
                </button>
            </div>
        </div>
    </div>
</div>
