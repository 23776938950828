import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiRequestService, ToastmessageService } from '@prekog/pw-common';
import { Examination } from 'src/app/pricelist/examination';

@Component({
  selector: 'app-addexaminationpopup',
  templateUrl: './updateexaminationpopup.component.html',
  styleUrls: ['./updateexaminationpopup.component.scss']
})
export class UpdateexaminationpopupComponent implements OnInit {
    selectedAlign: 'start' | 'center' | 'end';

    examinationForm?: FormGroup;

    examination: Examination = {};

    examinationList: Examination[] = [];

    showMinMaxPrice = false;

    regeneroBaseUrl = '';

    @ViewChild('priceInput') priceInput!: ElementRef;
    price: number | null = null;
    @ViewChild('minPriceInput') minPriceInput!: ElementRef;
    minPrice: number | null = null;
    @ViewChild('maxPriceInput') maxPriceInput!: ElementRef;
    maxPrice: number | null = null;

    constructor(
        private _matdialog: MatDialog,
        public router: Router,
        private _apiRequestService: ApiRequestService,
        @Inject('REGENERO_BASE_URL') private _regeneroBaseUrl: string,
        private _toastMessageService: ToastmessageService,
        @Inject(MAT_DIALOG_DATA) public specialityId: number,
        private _fb: FormBuilder) {


        this.selectedAlign = 'end';
        this.createExaminationForm();
        this.regeneroBaseUrl = _regeneroBaseUrl;
    }

    ngOnInit(): void {
        if(sessionStorage.getItem('updateExaminationList')) {
            this.examinationList = JSON.parse(sessionStorage.getItem('updateExaminationList') as string) as Examination[];
        }
    }


    createExaminationForm() {
        this.examinationForm = this._fb.group({
            name: ['', [Validators.required]]
        });
    }

    collectExaminationData() {
        this.examination = {
            specialityId:this.specialityId,
            name: this.examinationForm?.controls['name'].value as string | undefined,
            price: this.price!,
            minPrice:this.minPrice!,
            maxPrice:this.maxPrice!

        };

    }

    addExamination() {
        this.collectExaminationData();
        this.examinationList.push(this.examination);
        sessionStorage.setItem('updateExaminationList',JSON.stringify(this.examinationList));
        this.closeDialog();
    }

    closeDialog() {
        this._matdialog.closeAll();
    }

    onChangePrice() {
        this.price = this.priceInput.nativeElement.value;
    }

    onChangeMinPrice() {
        this.minPrice = this.minPriceInput.nativeElement.value;
    }

    onChangeMaxPrice() {
        this.maxPrice = this.maxPriceInput.nativeElement.value;
    }

    showMaxMinPrices() {
        this.showMinMaxPrice = !this.showMinMaxPrice;
        this.price = null;
    }
    showPrice() {
        this.showMinMaxPrice = !this.showMinMaxPrice;
        this.minPrice = null;
        this.maxPrice = null;
    }


}

