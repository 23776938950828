<div class="contact-content">
    <div>
        <h3>SZAKRENDELÉS TÍPUSÁNAK HOZZÁADÁSA</h3>
        <p [ngStyle]="{ 'text-align': 'center' }">A * - al jelölt mezők kitöltése kötelező!</p>
    </div>
    <div class="contact-form">
        <form [formGroup]="specialityTypeForm!" novalidate>
            <div class="field-container">
                <div class="field">
                    <b>Szakrendelés típusának neve *</b>
                    <span>Pl.:Szakorvosi vizsgálat</span>
                    <div class="data">
                        <input
                            [(ngModel)]="specialityType.name"
                            formControlName="name"
                            id="name"
                            class="form-control"
                            type="text"
                            required
                        />
                    </div>
                </div>
            </div>
            <div class="field-container">
                <div class="field">
                    <b>Szakrendelés típusának munkaköre *</b>
                    <span>Pl.:Szakorvosaink</span>
                    <div class="data">
                        <input
                            [(ngModel)]="specialityType.jobTitle"
                            formControlName="jobTitle"
                            id="jobTitle"
                            class="form-control"
                            type="text"
                            required
                        />
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div [ngStyle]="{ 'margin-top': '8px' }" class="field-container">
        <div [ngStyle]="{ 'justify-content': 'center', 'margin-top': '8px', 'margin-bottom': '8px' }" class="field">
            <div [ngStyle]="{ 'justify-content': 'center' }" class="data">
                <button
                    (click)="addSpecialityType()"
                    [ngStyle]="{ background: '#eee', border: '1px solid #0E8388', 'font-weight': 'bold', width: '100%' }"
                    [disabled]="!specialityTypeForm?.valid"
                >
                    SZAKRENDELÉS TÍPUSÁNAK MENTÉSE
                </button>
            </div>
        </div>
    </div>
</div>
