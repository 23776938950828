import { Component, Inject } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ApiRequestService, HttpError, MAT_ICON, TOAST_STATE, ToastmessageService } from '@prekog/pw-common';
import { DeleteDoctorSpecialityRequest } from './deletedoctorspecialityrequest';
import { DoctorSpeciality } from '../doctorspeciality';
import { DeleteDoctorSpecialityResponse } from './deletedoctorspecialityresponse';

@Component({
  selector: 'app-deletedoctorspecialitypopup',
  templateUrl: './deletedoctorspecialitypopup.component.html',
  styleUrls: ['./deletedoctorspecialitypopup.component.scss']
})
export class DeletedoctorspecialitypopupComponent {

    deleteDoctorSpecialityUrl = '/admin/delete-doctor-speciality';

    regeneroBaseUrl = '';

    selectedAlign: 'start' | 'center' | 'end';

    constructor(
        private _toastMessageService: ToastmessageService,
        private _apiRequestService: ApiRequestService,
        @Inject('REGENERO_BASE_URL') private _regeneroBaseUrl: string,
        private _matdialog:MatDialog,
        @Inject(MAT_DIALOG_DATA) public doctorSpeciality: DoctorSpeciality
    ) {
        this.regeneroBaseUrl = _regeneroBaseUrl;

        this.selectedAlign = 'end';
    }

      //TODO confirm popup!!!
      deleteExamination() {
        const deleteDoctorSpecialityRequest: DeleteDoctorSpecialityRequest = {
            doctorSpecialityId:this.doctorSpeciality.id

        };

        this._apiRequestService
            .sendRequest<DeleteDoctorSpecialityRequest, DeleteDoctorSpecialityResponse>(
                this.regeneroBaseUrl,
                true,
                deleteDoctorSpecialityRequest,
                true,
                this.deleteDoctorSpecialityUrl
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(
                            TOAST_STATE.error,
                            'Sikertelen törlés' + httpError.errorMessage,
                            MAT_ICON.error
                        );
                        this.dismissError();
                    });
                })
            )
            .subscribe((response:DeleteDoctorSpecialityResponse) => {
                this._toastMessageService.displayToastMessage(TOAST_STATE.success, response.responseMessage!, MAT_ICON.success);
                this.dismissError();
                this.closeDialog();
                window.location.reload();
            });
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }
    closeDialog() {
        this._matdialog.closeAll();
    }

}
