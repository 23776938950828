import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiRequestService, HttpError, MAT_ICON, TOAST_STATE, ToastmessageService, TranslationService } from '@prekog/pw-common';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import { Speciality } from '../../specialitypricelist/speciality';
import { AddSpecialityRequest } from './addspecialityrequest';
import { AddSpecialityResponse } from './addspecialityresponse';
import { DoctorWithChecked } from './doctorwithchecked';
import { GetDoctorResponse } from '../../doctors/getdoctorresponse';
import { Doctor } from '../../doctors/doctor';
import { AddexaminationpopupComponent } from './addexaminationpopup/addexaminationpopup.component';
import { MatDialog } from '@angular/material/dialog';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { Examination } from '../../pricelist/examination';
import { GetSpecialityTypesResponse } from './getspecialitytypesresponse';
import { SpecialityType } from './specialitytype';

@Component({
    selector: 'app-addspeciality',
    templateUrl: './addspeciality.component.html',
    styleUrls: ['./addspeciality.component.scss']
})
export class AddspecialityComponent implements OnInit, AfterViewChecked {

    specialityForm?: FormGroup;

    htmlForm?: FormGroup;

    speciality: Speciality = {};

    regeneroBaseUrl = '';

    id = 0;

    addSpecialityUrl = '/admin/add-speciality';

    html = '';

    doctors: DoctorWithChecked[] = [];

    getDoctorsUrl = '/regenero/get-doctors';

    isExtraSmall: Observable<BreakpointState>;

    examinationList: Examination[] = [];

    doctorList: Doctor[] = [];

    specialityTypeName: string = '';

    getSpecialityTypesUrl = '/admin/get-speciality-types';

    specialityTypeList: SpecialityType[] = [];

    specialityTypeId?: number;

    constructor(
        private _matdialog: MatDialog,
        public _router: Router,
        private _apiRequestService: ApiRequestService,
        @Inject('REGENERO_BASE_URL') private _regeneroBaseUrl: string,
        private _toastMessageService: ToastmessageService,
        private _fb: FormBuilder,
        private readonly breakpointObserver: BreakpointObserver,
        private ref: ChangeDetectorRef,
        private ngZone: NgZone,
        private _translateService: TranslationService,
    ) {
        this.regeneroBaseUrl = _regeneroBaseUrl;

        this.createSpecialityForm();

        this.createHtmlForm();

        this.isExtraSmall = this.breakpointObserver.observe(Breakpoints.XSmall);

    }

    ngAfterViewChecked(): void {
        this.ngZone.runOutsideAngular(() => {
            this.ref.detectChanges();
            setTimeout(() => {
                if (sessionStorage.getItem('examinationList')) {
                    const examinationList = JSON.parse(sessionStorage.getItem('examinationList') as string) as Examination[];
                    if (this.examinationList.length !== examinationList.length) {
                        this.examinationList = [...examinationList];
                    }
                }

            }, 0);
        });
    }

    ngOnInit(): void {
        if (!sessionStorage.getItem('token')) {
            this._router.navigateByUrl('/');
        }
        else {
            this.getDoctors();
            if (sessionStorage.getItem('examinationList')) {
                this.examinationList = JSON.parse(sessionStorage.getItem('examinationList') as string) as Examination[];

            }
        }
    }

    createSpecialityForm() {
        this.specialityForm = this._fb.group({
            name: ['', [Validators.required]],
            jobTitle: ['', [Validators.required]]


        });
    }

    createHtmlForm() {
        this.htmlForm = this._fb.group({
            html: ['', [Validators.required]],

        });
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }

    collectSpecialityData() {
        const specialityType: SpecialityType = this.specialityTypeList.filter(specialityType => specialityType.name === this.specialityTypeName)[0];
        this.speciality = {
            name: this.specialityForm?.controls['name'].value as string | undefined,
            jobTitle: this.specialityForm?.controls['jobTitle'].value as string | undefined,
            description: this.htmlForm?.controls['html'].value as string | undefined,
            isSpeciality: true,
            specialityType: specialityType


        };

        this.doctors.forEach((doctor) => {
            if (doctor.checked) {
                this.doctorList.push(doctor.doctor!);
            }
        })

    }

    addSpeciality() {
        if (this.examinationList.length === 0) {
            this._toastMessageService.displayToastMessage(TOAST_STATE.success, 'Kérem adjon hozzá tételt a szakrendeléshez!', MAT_ICON.success)
        }
        else {
            this.collectSpecialityData();
            this.examinationList.forEach((examination, index) => {
                examination.orderNumber = index;
            })
            const addSpecialityRequest: AddSpecialityRequest = {
                speciality: this.speciality,
                doctorList: this.doctorList,
                examinationList: this.examinationList
            }

            this._apiRequestService
                .sendRequest<AddSpecialityRequest, AddSpecialityResponse>(this.regeneroBaseUrl, true, addSpecialityRequest, true, this.addSpecialityUrl)
                .pipe(
                    catchError((error: HttpErrorResponse) => {
                        return throwError(() => {
                            const httpError: HttpError = error.error as HttpError;
                            if (error.status === 422) {
                                alert(this._translateService.translate(httpError.errorMessage!));
                            } else {
                                alert(this._translateService.translate('error.http'));
                            }
                        });
                    })
                )
                .subscribe((response: AddSpecialityResponse) => {
                    this._toastMessageService.displayToastMessage(TOAST_STATE.success, response.responseMessage!, MAT_ICON.success);
                    this.dismissError();
                    sessionStorage.removeItem('examinationList');
                    this._router.navigateByUrl('/specialities');


                });
        }
    }

    changeDoctor(doctorWithChecked: DoctorWithChecked) {
        this.doctors?.forEach((doctor => {
            if (doctorWithChecked.doctor?.name === doctor.doctor?.name) {
                doctor.checked = !doctor.checked
            }
        }))
    }

    getSpecialityTypes() {
        this._apiRequestService
            .sendRequest<{}, GetSpecialityTypesResponse>(this.regeneroBaseUrl, true, {}, true, this.getSpecialityTypesUrl)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(TOAST_STATE.error, 'HttpError hiba!', MAT_ICON.error);
                        this.dismissError();


                    });
                })
            )
            .subscribe((response: GetSpecialityTypesResponse) => {
                this.specialityTypeList = response.specialityTypeList as SpecialityType[];
                this.specialityTypeName = this.specialityTypeList[0].name!;


            });
    }
    getspeciality() {
        console.log(this.specialityTypeName);
    }
    getDoctors() {
        this._apiRequestService
            .sendRequest<{}, GetDoctorResponse>(this.regeneroBaseUrl, true, {}, true, this.getDoctorsUrl)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(TOAST_STATE.error, 'HttpError hiba!', MAT_ICON.error);
                        this.dismissError();


                    });
                })
            )
            .subscribe((response: GetDoctorResponse) => {
                const doctorList = response.doctors as Doctor[];
                const doctorWithCheckedList: DoctorWithChecked[] = [];
                doctorList.forEach((doctor) => {
                    const doctorWithChecked: DoctorWithChecked = {
                        doctor: doctor,
                        checked: false
                    }
                    doctorWithCheckedList.push(doctorWithChecked)
                })
                this.doctors = [...doctorWithCheckedList];

                this.getSpecialityTypes();

            });
    }

    openAddExaminationPopup() {
        const popupDialog = this._matdialog.open(AddexaminationpopupComponent, {
            width: 'calc(100% - 50px)',
            maxWidth: '100vw',
            height: '80%'
        });
        const xsmallDialogSubscription = this.isExtraSmall.subscribe((size: any) => {
            if (size.matches) {
                popupDialog.updateSize('100vw', '100%');
            } else {
                popupDialog.updateSize('calc(60% - 50px)', '');
            }
        });

        popupDialog.afterClosed().subscribe(() => {
            xsmallDialogSubscription.unsubscribe();
        });
    }

    removeExamination(index: number) {

        window.location.reload()
        if (sessionStorage.getItem('examinationList')) {
            if (this.examinationList.length !== 1) {
                this.examinationList.splice(index, 1);
                sessionStorage.removeItem('examinationList');
                sessionStorage.setItem('examinationList', JSON.stringify(this.examinationList));
            }
            else {
                sessionStorage.removeItem('examinationList');
            }
        }



    }


}
