import { DoctorSpeciality } from "../specialities/updatespeciality/doctorspeciality";
import { DoctorConsultingHour } from "./updatedoctor/doctorconsultinghour";

export class Doctor {
    id?: number;
    name?: string;
    reservationLink?: string;
    introduction?: string;
    image?: string;
    routerLink?:string;
    thumbnailUrl?:string;
    doctorConsultingHourList?:DoctorConsultingHour[];
    doctorSpecialityList?:DoctorSpeciality[];

}
